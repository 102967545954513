@import "./colors";

$breakpoint-phone: 767.98px;
$breakpoint-tablet: 1023.98px;
$breakpoint-desktop: 1200px;
$max-height-upgrade: 670px;
$breakpoint-phone-medium: 420px;
$breakpoint-phone-small: 300px;

$comparison-border-weight: 2px;

///
/// Default font stack, size, line-height
///
$font-family: 'Montreux Grotesk Soft', sans-serif;
$font-size: 18px;
$line-height: 1.5555;

$weight-regular: 400;
$weight-book: 350;
$weight-demibold: 600;
$weight-bold: 700;

$plain-text: $color-black;

$border: $color-sky;

///
/// Viewports
///
$maxSm: 342px;
$minSm: 0;
$minMd: 625px;
$minMdl: 850px;
$minLg: 1121px;
$minXl: 1393px;


///
/// Website Viewports
///
$breakpoints: (
    sm: (width:      0, gap: 32px),
    maxSm: (width: 324px, gap: 32px),
    md: (width:  625px, gap: 32px),
    mdl: (width: 850px, gap: 32px),
    lg: (width: 1121px, gap: 32px),
    lgx: (width: 1170px, gap: 32px),
    xl: (width: 1393px, gap: 32px),
) !default;

///
/// Shadows
///
$low-shadow: 0 0px 0.5px rgba(0, 0, 0, 0.057), 0 0px 1.8px rgba(0, 0, 0, 0.083), 0 0px 8px rgba(0, 0, 0, 0.14);
$med-shadow: 0 0.5px 1.1px rgba(0, 0, 0, 0.057), 0 1.8px 3.6px rgba(0, 0, 0, 0.083), 0 8px 16px rgba(0, 0, 0, 0.14);
$high-shadow: 0 1.1px 2.1px rgba(0, 0, 0, 0.057), 0 3.6px 7.1px rgba(0, 0, 0, 0.083), 0 16px 32px rgba(0, 0, 0, 0.14);


///
/// Easings and Durations
///
$ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
$ease-in-quint: cubic-bezier(0.64, 0, 0.78, 0);
$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);

$fade-easing: $ease-out-quint;
$entrance-easing: $ease-out-quint;
$exit-easing: $ease-in-quint;

///
/// These are recommended durations for transitions. They are custom properties
/// so they can be set to 0 when a visitor prefers-reduced-motion.
///
/// Fast is for micro-animations such as fades.
///
$fast: 150ms;

///
/// Moderate is for mid-sized animations, such as mega menus and notifications.
///
$moderate: 250ms;

///
/// Slow is for animations meant for large animations such as page transitions
/// and background fades.
///
$slow: 400ms;


///
/// Hamburger Button Overrides
///
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 31px;
$hamburger-layer-height: 5px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $color-magenta;

///
/// Max widths
/// 
/// These are the max widths for the content area of the site. They are used to
/// set the max-width of the content area in the layout.
/// 
$max-width-sign-in-carousel: 440px;
$max-width-sign-in-form: 570px;
